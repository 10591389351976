<template lang="pug">
div
  Table.app-table(:columns="columns" :data="listData" border)
    template(slot-scope="{ row, index }" slot='isSuper')
      Icon(
        :type="row.isSuper ? 'md-checkmark' : 'md-close'"
        :color="row.isSuper ? '#19be6b' : '#ff9900'"
      )

    template(slot-scope="{ row, index }" slot="createdAt")
      span {{ timeFormat(row.createdAt)  }}

    template(slot-scope="{ row, index }" slot="username")
      span.pointer(
        @click="searchUser(row.username)"
      ) {{ row.username}} / {{ row.fullname || row.name || row.username }}

    template(slot-scope="{ row, index }" slot="authGroup")
      Select.app-tool.app-tool-select(
        v-model="row.meta.authGroup.id"
        @on-change="setupAuthGroup(row)"
        filterable
      )
        Option(
          v-for="item in authGroupList"
          :value="item.id"
          :key="`auth-group-${item.id}`"
          :label="item.name"
        ) {{ item.name }}

    template(slot-scope="{ row, index }" slot="operator" v-if="row.operator")
      span {{ row.operator.name }}

    template(slot-scope="{ row, index }" slot="name")
      span.pointer(
        @click="searchUser(row.name)"
      ) {{ row.name }}

    template(slot-scope="{ row, index }" slot="locale")
      span {{ $t(`locales.${row.locale}`) }}

    template(slot-scope="{ row, index }" slot="currency")
      span {{ $t(`UseCurrency.${row.currency}`) }}

    template(slot-scope="{ row, index }" slot="actions")
      Button.actions(
        type="success"
        size='small'
        icon="md-create"
        v-if="functions.editButton"
        @click="showUserEditor(row)"
      )

      Button.actions(
        type="warning"
        size='small'
        icon="ios-game-controller-a"
        v-if="resetCacheVisible"
        @click="showUserGameEditor(row)"
      )

      Button.actions(
        type="error"
        size='small'
        icon="logo-usd"
        v-if="balanceUpdateVisible"
        @click="showBalanceEditor(row)"
      )

  UserEditor(
    :form-data="userData"
    edit-type="edit"
    :on-update="onUpdateUser"
    :visible="userEditorVisible"
    :on-close="closeUserEditor"
  )

  UserGameEditor(
    :form-data="userGameData"
    edit-type="edit"
    :on-update="onUpdateUser"
    :visible="userGameEditorVisible"
    :on-close="closeUserGameEditor"
  )

  BalanceEditor(
    :form-data="balanceData",
    :on-update="onBalanceCreate"
    :visible="balanceEditorVisible"
    :on-close="closeBalanceEditor"
  )

</template>
<script>
import Time from '~m/time'
import { mapActions, mapGetters } from 'vuex'
import to from 'await-to-js'
import moment from 'moment-timezone'
import UserEditor from '~c/user-editor'
import UserGameEditor from '~c/user-game-editor'
import BalanceEditor from '~v/user/balance-editor'

export default {
  name: 'user-table',
  mixins: [
    Time
  ],
  components: {
    UserEditor,
    UserGameEditor,
    BalanceEditor
  },
  data () {
    return {
      modalStatus: false,
      userEditorVisible: false,
      userGameEditorVisible: false,
      balanceEditorVisible: false,
      userData: null,
      userGameData: null,
      balanceData: null
    }
  },
  props: {
    listData: Array,
    hasOperator: Boolean,
    userInfo: Object
  },
  watch: {
  },
  computed: {
    ...mapGetters('authGroup', { authGroupData: 'getAllForSelector' }),
    ...mapGetters('game', { allGames: 'getAllForSelector' }),
    authGroupList () {
      //
      return [
        { id: 'default', name: this.$t('Default group'), value: 'default' },
        // { id: 'customized', name: this.$t('Customized'), value: 'customized' },
        ...this.authGroupData || []
      ]
    },
    resetCacheVisible () {
      return this.role === 'user' && this.functions.cacheClean
    },
    balanceUpdateVisible () {
      return this.role === 'user' && this.userInfo.isAdministrator && this.functions.balanceUpdate
    },
    role () {
      return this.$route.path.split('/')[2] || 'user'
    },

    functions () {
      return this.userInfo.sidebarMap[`users/${this.role}`].functions
    },

    // 遊戲表單
    columns () {
      let width = 95
      const role = this.role
      const myRole = role ? role.toLowerCase() : null

      switch (myRole) {
        // case 'user':
        // case 'provider':
        //   width = 157
        //   break
        case 'operator':
          width = 48
          break
      }

      let cols = [
        {
          title: 'ID',
          key: 'id',
          width: 90,
          align: 'center',
          fixed: window.innerWidth <= 400 ? 'left' : ''
        },
        {
          title: this.$t('Created at'),
          slot: 'createdAt',
          align: 'center',
          ellipsis: true,
          minWidth: 150
        },
        {
          title: this.$t('User username'),
          slot: 'username',
          // width: 150,
          // ellipsis: true,
          minWidth: 170
        },
        // {
        //   title: this.$t('User fullname'),
        //   slot: 'name',
        //   // width: 150,
        //   ellipsis: true,
        //   minWidth: 150
        // },
        {
          title: this.$t('User language'),
          slot: 'locale',
          align: 'center',
          width: 75
        },
        {
          title: this.$t('User currency'),
          slot: 'currency',
          align: 'center',
          width: 65
        },
        {
          title: this.$t('User timezone'),
          key: 'timezone',
          align: 'center',
          width: 80
        },
        {
          title: this.$t('User actived'),
          key: 'actived',
          render: this.renderSwitch,
          align: 'center',
          width: 85
        },
        {
          title: this.$t('Table actions'),
          slot: 'actions',
          width
        }
      ]

      if (['operator'].includes(role)) {
        const idx = _.findIndex(cols, { key: 'username' })
        cols.splice(idx, 0, {
          title: this.$t('{role} code', { role: this.$t(role) }),
          key: 'code',
          minWidth: 150,
          ellipsis: true
        })

        cols.splice(cols.length - 3, 0, {
          title: this.$t('Name Display'),
          key: 'nameDisplayOn',
          render: this.renderSwitchNameDisplay,
          align: 'center',
          width: 85
        })

        cols.splice(cols.length - 3, 0, {
          title: this.$t('Gem'),
          key: 'gemSystemOn',
          render: this.renderSwitchGem,
          align: 'center',
          width: 85
        })

        cols.splice(cols.length - 3, 0, {
          title: this.$t('Slot Tables'),
          key: 'slotTablesOn',
          render: this.renderSwitchTables,
          align: 'center',
          width: 85
        })

        cols.splice(cols.length - 2, 0, {
          title: this.$t('Is super'),
          slot: 'isSuper',
          align: 'center',
          width: 68
        })

        cols.splice(cols.length - 3, 0, {
          title: this.$t('UserTable.isBeta'),
          key: 'isBeta',
          render: this.renderTagSwitch,
          align: 'center',
          width: 85
        })
      } else if (role === 'user' && this.userInfo.isAdministrator) {
        // 設定 test 欄位
        cols.splice(cols.length - 2, 0, {
          title: this.$t('UserTable.test'),
          key: 'test',
          render: this.renderTagSwitch,
          align: 'center',
          width: 85
        })
      }

      if (['sub-operator', 'user', 'operator'].includes(role) && ['Administrator', 'SuperAdministrator', 'SuperOperator', 'Operator', 'SubOperator', 'CustomerService'].includes(this.userInfo.role)) {
        cols.splice(2, 0, {
          title: this.$t('Operator'),
          slot: 'operator',
          width: 120
        })
      }

      if (['administrator', 'customer-service'].includes(role) && ['Administrator', 'SuperAdministrator', 'SuperOperator', 'Operator', 'SubOperator'].includes(this.userInfo.role)) {
        cols.splice(3, 0, {
          title: this.$t('Auth group'),
          slot: 'authGroup',
          width: 200
        })
      }

      return cols
    }
  },
  async mounted () {
    await this.getAuthGroup()
  },
  methods: {
    ...mapActions('authGroup', { getAuthGroup: 'findAll', applyAuthGroup: 'apply' }),
    ...mapActions('auth', ['logout']),
    ...mapActions('user', {
      updateUser: 'update',
      createUser: 'create',
      findUser: 'findOne'
    }),
    ...mapActions('balancelog', {
      updateBalance: 'create'
    }),
    async setupAuthGroup (row) {
      const data = { id: row.meta.authGroup.id, data: { users: [row.id] } }
      const [err] = await to(this.applyAuthGroup(data))
      if (err) {
        this.$Message.error(this.$t('Update fail'))
      }
    },
    toPath (path, { role, id, operatorId, username }) {
      switch (role) {
        case 'User':
          return `/${path}?search=${username}&operator=${operatorId}`
        case 'Provider':
          return `/${path}?provider=${id}`
        case 'Operator':
        case 'SuperOperator':
          return `/${path}?operator=${id}`
      }
    },
    renderSwitchGem (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.gemSystemOn === 1 || row.gemSystemOn === true,
          disabled: !this.userInfo.isAdministrator
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                gemSystemOn: val
              }
              dispatch('user/update', { id: row.id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' } }),
        h('Icon', { slot: 'close', props: { type: 'md-close' } })
      ])
    },
    renderTagSwitch (h, data) {
      const { dispatch } = this.$store
      const { row, column } = data
      const { key } = column
      return h('i-switch', {
        props: {
          value: row[key],
          disabled: !this.userInfo.isAdministrator || !this.functions.editButton
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {}
              data[key] = !!(val)
              dispatch('user/update', { id: row.id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' } }),
        h('Icon', { slot: 'close', props: { type: 'md-close' } })
      ])
    },
    renderSwitchTables (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.slotTablesOn === 1 || row.slotTablesOn === true,
          disabled: !this.userInfo.isAdministrator || !this.functions.editButton
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                slotTablesOn: val
              }
              dispatch('user/update', { id: row.id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' } }),
        h('Icon', { slot: 'close', props: { type: 'md-close' } })
      ])
    },
    renderSwitchNameDisplay (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.nameDisplayOn === undefined || row.nameDisplayOn === 1 || row.nameDisplayOn === true,
          disabled: !this.userInfo.isAdministrator || !this.functions.editButton
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                nameDisplayOn: val
              }
              dispatch('user/update', { id: row.id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' } }),
        h('Icon', { slot: 'close', props: { type: 'md-close' } })
      ])
    },
    renderSwitch (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.actived,
          disabled: !this.userInfo.isAdministrator
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                actived: !row.actived
              }
              dispatch('user/update', { id: row.id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' } }),
        h('Icon', { slot: 'close', props: { type: 'md-close' } })
      ])
    },

    async onUpdateUser () {
      if (!this.userData.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closeUserEditor()
      }

      let dataToUpdate = _.pick(this.userData, ['name', 'fullname', 'key', 'pass', 'passConfirm', 'timezone', 'operatorId', 'balances', 'locale', 'currency', 'providerSettings', 'role', 'parentOperatorId', 'games', 'transactionThrottle', 'transactionDuration', 'npcOn', 'meta'])
      const { pass, providerSettings } = dataToUpdate
      dataToUpdate = _.omit(dataToUpdate, ['passConfirm'])

      if (!pass) {
        dataToUpdate = _.omit(dataToUpdate, ['pass'])
      }

      if (!providerSettings || !providerSettings.name) {
        dataToUpdate = _.omit(dataToUpdate, ['providerSettings'])
      }

      if (dataToUpdate.role === 'SuperOperator') delete dataToUpdate.parentOperatorId
      dataToUpdate.utcOffset = moment.tz(dataToUpdate.timezone).utcOffset()

      dataToUpdate.meta = _.pick(dataToUpdate.meta, ['wallet', 'ipWhiteList'])

      let [err] = await to(this.updateUser({ id: this.userData.id, data: dataToUpdate }))
      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('User update error'))
      }

      this.$Message.success(this.$t('User update seccessful'))
      // this.findGame(data)
      this.closeUserEditor()
    },

    async showUserEditor (data) {
      this.userData = await this.findUser({ id: data.id })
      const transaction = _.get(this.userData, 'meta.apiLimiter.transaction') || {}
      const { throttle, duration } = transaction
      if (throttle) {
        this.userData.transactionThrottle = throttle
      }

      if (duration) {
        this.userData.transactionDuration = duration
      }
      this.userEditorVisible = true
    },

    showUserGameEditor (data) {
      this.userGameData = {
        userId: data.id,
        gameId: this.allGames[0].value
      }
      this.userGameEditorVisible = true
    },

    showBalanceEditor (data) {
      const { balances, username, name, id, key } = data
      balances.forEach(bl => {
        bl.amountAdd = 0
        bl.gemAdd = 0
        bl.actPointAdd = 0
        bl.laijiCoinAdd = 0
        bl.profitAdd = 0
        bl.commentTitle = this.$t('Manual charge')
        bl.comment = ''
      })

      this.balanceData = {
        name,
        username,
        id,
        key,
        balances
      }
      this.balanceEditorVisible = true
    },

    async onBalanceCreate () {
      if (!this.balanceData.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closeBalanceEditor()
      }

      const data = []
      for (const balance of this.balanceData.balances) {
        const { amountAdd: amount, gemAdd: gem, actPointAdd: actPoint, laijiCoinAdd: laijiCoin, profitAdd: profit, _id, commentTitle, comment } = balance
        if (amount || gem || profit) {
          data.push({
            amount,
            gem,
            actPoint,
            laijiCoin,
            profit,
            _id,
            comment: comment ? `${commentTitle}-${comment}` : commentTitle
          })
        }
      }

      if (!data.length) return false

      let [err] = await to(this.updateBalance({ data }))
      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Balance update error'))
      }

      const dataToUpdate = _.pick(this.balanceData, ['key', 'id'])
      let [err2] = await to(this.updateUser({ id: dataToUpdate.id, data: dataToUpdate }))
      if (err2) {
        console.log(err2)
        return this.$Message.error(this.$t('Balance update error'))
      }

      this.$Message.success(this.$t('Balance update seccessful'))
      this.closeBalanceEditor()
    },

    closeUserEditor () {
      this.userEditorVisible = false
      setTimeout(() => {
        this.userData = null
      }, 500)
    },

    closeUserGameEditor () {
      this.userGameEditorVisible = false
      setTimeout(() => {
        this.userData = null
      }, 500)
    },

    closeBalanceEditor () {
      this.balanceEditorVisible = false
      setTimeout(() => {
        this.balanceData = null
      }, 500)
    },
    searchUser (fullname) {
      this.$emit('after-click-fullname', fullname)
    }
  }
}
</script>
<style lang="sass" scoped>
.ivu-table-expanded-cell
  padding: 16px
.pointer
  color: #2b85e4
  cursor: pointer
  &:hover
    color: #5cadff
  &:active
    color: #2d8cf0
</style>
