<template lang="pug">
Modal(
    :mask-closable="true",
    :styles="{top: '50px', margin: '0px auto 50px'}"
    :value="visible"
    @on-visible-change="onVisibleChange"
    :title="$t('Reset usergame')"
)
  .modal-editor
    template(v-if="formData")
      .content-part
        Row(align="middle")
          Col.title(span="8")
            h3 {{$t('Game')}}

          Col.val(span="16")
            Select(
              v-model="formData.gameId"
              filterable
            )
              Option(
                style="text-align: left;"
                v-for="(row, index) in allGames"
                :key="`game-${index}`"
                :value="row.value"
                :label="row.label"
              ) {{ row.label }}

  .footer(slot='footer')
    template
      Row
        Col(span="18" offset="6")
          Button(icon="md-close" @click="onClose") {{$t('Cancel')}}
          Button(icon="md-checkmark" type="primary" @click="handleSubmit" :loading="isUpdating") {{$t('Confirm')}}

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'user-game-editor',
  data () {
    return {
      searchTimeout: null,
      isUpdating: false,
      users: [],
      tagInputText: '',
      separate: ['Enter', 'Comma'],
      searchTimer: null,
      mechanisms: [
        {
          label: this.$t('slot-erase-any-times-1'),
          value: 'slot-erase-any-times-1'
        },
        {
          label: this.$t('slot-erase-link-times-2'),
          value: 'slot-erase-link-times-2'
        },
        {
          label: this.$t('bankerOxOx'),
          value: 'bankerOxOx'
        },
        {
          label: this.$t('casinoOxOx'),
          value: 'casinoOxOx'
        },
        {
          label: this.$t('slot1'),
          value: 'slot1'
        },
        {
          label: this.$t('slot2'),
          value: 'slot2'
        },
        {
          label: this.$t('slot3'),
          value: 'slot3'
        },
        {
          label: this.$t('slot-erase-1-spin'),
          value: 'slot-erase-1-spin'
        }
      ]
    }
  },

  props: {
    formData: Object,
    onClose: Function,
    visible: Boolean
  },

  watch: {
    formData: {
      handler (nv, ov) {
        if (!this.formData) {
          return
        }

        if (this.formData && !this.formData.isDirty) {
          this.formData.isDirty = !this.formData.isDirty
        }
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('game', {
      allGames: 'getAllForSelector'
    })
  },
  methods: {
    ...mapActions('userGame', { updateUserGame: 'update' }),

    async handleSubmit () {
      this.isUpdating = true
      const { userId, gameId } = this.formData
      const data = {
        userId,
        gameId
      }

      await this.updateUserGame({ id: userId, data })
      this.isUpdating = false
      this.onClose()
    },

    onVisibleChange (boolean) {
      if (!boolean) {
        this.isUpdating = false
      }

      if (this.formData) this.formData.isReady = true
    }
  }
}
</script>
<style lang="sass" scoped>
.input-prefix
  line-height: 34px
  position: absolute
  left: 0
  padding-left: 8px
  color: #aaa

.dot
  float: left
  width: 12px
  height: 12px
  background-color: #ccc
  border-radius: 6px
  margin: 1px 6px 0 0
  &.online
    background-color: #19be6b

.tag-container
  width: 100%
  float: left

.tag-input
  margin-top: 12px

.pull-right
  float: right
</style>
