<template lang="pug">
.content(
  v-infinite-scroll="fetchData",
  infinite-scroll-disabled="isUserBusy",
  :infinite-scroll-distance="scrollDistance",
  infinite-scroll-immediate-check="false"
)
  Content.app-content
    Row
      Col(span='24')
        Select.app-tool(
          v-model="filter"
          :placeholder="$t('Please select role')"
          prefix="md-contact"
          @on-change="onRoleSelectorChange"
          v-if="userInfo.isAdministrator && isOperatorPage"
        )
          Option(v-for="(option, index) in operatorRoleList", :value="option.value", :key="option.value") {{option.label}}

        Input.app-tool(
          v-model="search"
          icon="ios-search"
          clearable
          :placeholder="$t('Search user name')"
          name="search-user-name"
          @on-change='onSearchInputChange'
        )

        SearchBtn(
          :fetchData="fetchData"
          :loading="loading"
        )

        Button.app-tool-right(
          type="primary"
          icon="md-person-add"
          v-if="editBtnVisible"
          @click="showUserEditor"
        ) {{ addBtnText }}

    //- 主表單內容
    Row(:style="{ marginTop: '16px' }")
      Col(span='24' v-if="userList.length || !userListLoading")
        UserTable(
          :list-data="userList",
          :has-operator="hasOperator",
          :user-info="userInfo",
          @after-click-fullname="afterClickFullname"
        )

      Col(span='24', class="table-loading-col", v-if="userListLoading")
        Spin(fix)
          Icon(type="ios-loading", size=18 class="spin-icon")

  UserEditor(
    :form-data="userData",
    edit-type="new"
    :on-update="onUserCreate"
    :visible="userEditorVisible"
    :on-close="closeUserEditor"
  )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserTable from '~v/user/user-table'
import UserEditor from '~c/user-editor'
import Scroll from '~m/scroll'
import UUID from '~m/uuid'
import RouterEvents from '~m/router-events'
import to from 'await-to-js'
import moment from 'moment-timezone'
import SearchBtn from '~c/search-btn'

export default {
  name: 'user-list',
  components: {
    UserTable, UserEditor, SearchBtn
  },
  mixins: [Scroll, UUID, RouterEvents],
  // view 裡大部份的 data 是用來做過濾器用的，主要是同步 url 上的參數，部份 data 是做過濾器的暫存
  data () {
    return {
      userData: null,
      userEditorVisible: false,
      filter: null,
      search: '',
      isUserEditorUpdating: false,
      operatorRoleList: [
        {
          value: 'all',
          label: this.$t('All operator roles')
        },
        {
          value: 'SuperOperator',
          label: this.$t('SuperOperator')
        },
        {
          value: 'Operator',
          label: this.$t('Operator')
        }
      ]
    }
  },

  watch: {
    // 監聽網址的變化進行 data 重設
    async '$route' (nv, ov) {
      this.resetSelector()
    }
  },

  // 所有內容的多數 data 來自於 vuex 的 state 以 module 裡的 getter 的方式嵌入 computed 中
  computed: {
    // 嵌入遊戲的 state 資料
    ...mapGetters('user', {
      userList: 'getListData',
      userListLoading: 'getLoadingStatus',
      hasNextUser: 'hasNext'
    }),

    functions () {
      return this.userInfo.sidebarMap[`users/${this.role}`].functions
    },

    editBtnVisible () {
      return (this.userInfo.isAdministrator || this.userInfo.isSuperOperator || this.userInfo.isOperator) && !this.isUserPage && this.functions.addButton
    },

    role () {
      return this.$route.path.split('/')[2] || 'user'
    },

    isOperatorPage () {
      return ['operator'].includes(this.role)
    },

    isAdministratorPage () {
      return this.role === 'administrator'
    },

    isCustomerServicePage () {
      return this.role === 'customer-service'
    },

    isProviderPage () {
      return this.role === 'provider'
    },

    isSubOperatorPage () {
      return this.role === 'sub-operator'
    },

    isUserPage () {
      return this.role === 'user'
    },

    // 遊戲內容是否在讀取
    isUserBusy () {
      return this.userListLoading || !this.hasNextUser
    },

    loading () {
      return this.userListLoading
    },

    hasOperator () {
      return /[0-9]+/.test(`${this.operator}`)
    },

    addBtnText () {
      if (!this.$route.path) return ''
      return this.$t(`Add new ${this.$route.path.split('/')[2]}`)
    },
    addCurrency () {
      let temp
      if (this.userInfo.isAdministrator) {
        temp = this.$route.query.currency || 'TWD'
      } else {
        temp = this.userInfo.currency || 'TWD'
      }
      return temp
    }

  },

  methods: {
    ...mapActions('user', { findUser: 'find', createUser: 'create', resetUser: 'reset' }),
    // 向 API 取得 data
    // infinity scroll 需綁定
    fetchData (reset = false) {
      let { query } = this.$route
      let { search, operator, filter, currency } = query
      if (this.selectedOperator && !operator) {
        return this.onOperatorChange(this.selectedOperator.id)
      }
      operator = operator === 'all' ? '' : operator
      let role = this.role
      filter = filter === 'all' ? '' : filter
      search = search === '' ? null : search

      if (this.isProviderPage) operator = null
      if (reset) {
        this.resetUser({ operator, search, role, filter, currency })
      }

      if (this.isUserBusy) return
      this.findUser({ operator, search, role, filter, currency })
    },

    // 依據 route 重設所有過濾條件
    resetSelector () {
      const { filter, search } = this.$route.query
      this.filter = filter || 'all'
      this.search = search || ''
      if (this.isInstantSearch()) this.fetchData(true)
    },

    async onUserCreate () {
      if (!this.userData.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closeUserEditor()
      }

      // this.$Message.success(this.$t('profile is modify'))
      let dataToCreate = _.pick(this.userData, ['username', 'pass', 'passConfirm', 'name', 'fullname', 'key', 'code', 'isSuper', 'parentOperatorId', 'role', 'timezone', 'currency', 'locale'])
      if (dataToCreate.pass && dataToCreate.pass !== dataToCreate.passConfirm) {
        return this.$Message.error(this.$t('The two password do not match'))
      }

      let omit = ['passConfirm', 'parentCode']
      if (!dataToCreate.pass) {
        omit = [...omit, 'pass']
      }

      if (!this.isOperatorPage && !this.isSubOperatorPage) {
        omit = [...omit, 'isSuper', 'parentOperatorId']
      } else {
        dataToCreate.parentOperatorId *= 1
        if (!dataToCreate.parentOperatorId) {
          delete dataToCreate.parentOperatorId
        }
      }

      dataToCreate = _.omit(dataToCreate, omit)
      dataToCreate.utcOffset = moment.tz(dataToCreate.timezone).utcOffset()

      let [err] = await to(this.createUser({ data: dataToCreate }))
      if (err) {
        console.log(err)
        return this.$Message.error(err.message)
      }

      this.$Message.success(this.$t('User update seccessful'))
      this.closeUserEditor()
      this.fetchData(true)
    },

    showUserEditor () {
      this.userData = {
        username: '',
        pass: '',
        passConfirm: '',
        name: '',
        fullname: '',
        currency: this.addCurrency,
        email: '',
        code: '',
        locale: this.userInfo.locale || 'zh-tw',
        parentOperatorId: this.userInfo.id,
        key: this.getUUID().replace(/-/g, ''),
        timezone: this.userInfo.timezone || moment.tz.guess(),
        provider: {
          name: 'twgaming', // 自定義名稱
          logo: '', // filename
          bg: '' // filename
        }
      }

      if (this.isOperatorPage) {
        this.userData = {
          ...this.userData,
          isSuper: false,
          parentOperatorId: this.userInfo.isSuperOperator ? this.userInfo.id : 0,
          role: 'Operator'
        }
      } else if (this.isProviderPage) {
        this.userData = {
          ...this.userData,
          category: 'slot',
          role: 'Provider'
        }
      } else if (this.isSubOperatorPage) {
        this.userData = {
          ...this.userData,
          category: 'slot',
          role: 'SubOperator'
        }
      } else if (this.isAdministratorPage) {
        this.userData = {
          ...this.userData,
          category: 'slot',
          role: 'Administrator'
        }
      } else if (this.isCustomerServicePage) {
        this.userData = {
          ...this.userData,
          category: 'slot',
          role: 'CustomerService'
        }
      } else {
        this.$Message.error(this.$t(`Method not allowed.`))
      }

      this.userEditorVisible = true
    },

    closeUserEditor () {
      this.userEditorVisible = false
      setTimeout(() => {
        this.userData = null
      }, 500)
    },
    afterClickFullname (username) {
      if (this.search !== username) {
        this.search = username
        this.onSearchInputChange()
      }
    }
  },
  async mounted () {
    this.resetSelector()
  },
  beforeDestroy () {
    this.resetUser()
  }
}

</script>

<style lang="sass">
</style>
