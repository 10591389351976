<template lang="pug">
.app-tool(style="float: left; margin-left: 14px;")
  Button(
    :disabled="loading"
    type="primary"
    @click="doSearch"
  ) {{ $t("Search") }}

  //- Checkbox(
  //-   v-model="autoSearch"
  //-   :disabled="loading"
  //-   style="margin-left: 12px;"
  //-   @on-change="onCheckChange"
  //- ) {{ $t('Auto search') }}

</template>

<script>
export default {
  name: 'SearchBtn',
  data () {
    return {
      autoSearch: false
    }
  },
  props: {
    fetchData: Function,
    loading: Boolean
  },
  methods: {
    onCheckChange () {
      // this.autoSearch = !this.autoSearch
      console.log(this.autoSearch)
      localStorage.setItem('instantSearch', this.autoSearch)
    },
    doSearch () {
      this.fetchData(true)
    }
  },
  computed: {
    instantSearch () {
      return JSON.parse(localStorage.getItem('instantSearch') || 'false')
    }
  },
  mounted () {
    this.autoSearch = this.instantSearch
  }
}
</script>
<style lang="sass" scoped>
</style>
