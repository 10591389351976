<template lang="pug">
Modal(
  :mask-closable="true"
  :styles="{top: '50px', margin: '0px auto 50px'}"
  :value="visible"
  @on-visible-change="visibleChange"
  @on-cancel="onClose"
  :title="$t('Manual balance update')"
)
  Form.modal-editor(
    :model="formData"
    ref="balanceEditorForm"
    v-if="formData"
  )
    //- 檢視用戶用內容
    template
      .content-part
        Row
          Col.title(span="9")
            h3 {{ $t('Account') }}
          Col.val(span="12" offset="3")
            span {{ formData.username }}

        Row
          Col.title(span="9")
            h3 {{ $t('User fullname') }}
          Col.val(span="12" offset="3")
            span {{ formData.name }}

        Row
          Col.title(span="9")
            h3 {{$t('User id')}}
          Col.val(span="12" offset="3")
            span {{ formData.id }}

        Row
          Col.title(span="9")
            h3 {{$t('User full name')}}
          Col.val(span="12" offset="3")
            span {{ formData.fullname }}

      .content-part(v-for="(item, idx) in formData.balances")
        Divider(orientation="left")
          span {{ item.provider.name }}

        .row
          Row
            Col.title(span="9")
              h3 {{$t('Total stake')}}
            Col.val(span="12" offset="3")
              span {{ currencyFormat(item.totalStake) }}

          Row
            Col.title(span="9")
              h3 {{$t('Total win')}}
            Col.val(span="12" offset="3")
              span {{ currencyFormat(item.totalWin) }}

          Row
            Col.title(span="9")
              h3 {{$t('Profit')}}
            Col.val(span="12" offset="3")
              span {{ currencyFormat(item.profit) }}

          Divider(orientation="left")

          Row
            Col.title(span="9")
              h3 {{$t('Balance comment')}}
            Col(span="5" offset="10")
              Select(
                v-model="item.commentTitle"
                @on-change="onCommentIdChange(idx)"
              )
                Option(v-for="bl in balanceActions" :value="bl.name" :key="`comment-${bl.id}`") {{ bl.name }}

          Row
            Col.title(span="9")
              h3 {{$t('Comment')}}
            Col(span="11" offset="4")
              Input(
                v-model="item.comment"
                type="textarea"
              )

          Row
            Col.title(span="9")
              h3 {{$t('Balance amount')}}
            Col.val(span="7" offset="3")
              span(style="padding-right: 12px") {{ currencyFormat(item.amount) }}
            Col.val(span="5" style="text-align: center;")
              InputNumber(
                v-model="item.amountAdd"
                :step="1000"
              )

          Row
            Col.title(span="9")
              h3 {{$t('Gem amount')}}
            Col.val(span="7" offset="3")
              span(style="padding-right: 12px") {{ item.gemAmount || 0 }}
            Col.val(span="5" style="text-align: center;")
              InputNumber(
                v-model="item.gemAdd"
                :step="1000"
              )

          Row
            Col.title(span="9")
              h3 {{$t('ActPoint amount')}}
            Col.val(span="7" offset="3")
              span(style="padding-right: 12px") {{ item.actPointAmount || 0 }}
            Col.val(span="5" style="text-align: center;")
              InputNumber(
                v-model="item.actPointAdd"
                :step="1000"
              )
          Row
            Col.title(span="9")
              h3 {{$t('LaijiCoin amount')}}
            Col.val(span="7" offset="3")
              span(style="padding-right: 12px") {{ item.laijiCoinAmount || 0 }}
            Col.val(span="5" style="text-align: center;")
              InputNumber(
                v-model="item.laijiCoinAdd"
                :step="1000"
              )

          //- Row
          //-   Col.title(span="9")
          //-     h3 {{$t('Profit')}}
          //-   Col.val(span="7" offset="3")
          //-     span(style="padding-right: 12px") {{ currencyFormat(item.profit) }}
          //-   Col.val(span="5" style="text-align: center;")
          //-     InputNumber(
          //-       v-model="item.profitAdd"
          //-       :step="1000"
          //-     )
  .footer(slot='footer')
    template
      Button(
        icon="md-close"
        @click="onClose"
        :disabled="isUpdating"
      ) {{$t('Cancel')}}

      Button(icon="md-checkmark"
        type="primary"
        @click="updateBalance"
        :loading="isUpdating"
      ) {{$t('Confirm')}}

</template>
<script>
import UUID from '~m/uuid'
import Currency from '~m/currency'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'balance-editor',
  mixins: [UUID, Currency],
  data () {
    return {
      prefixWidth: 0,
      isUpdating: false,
      showComment: {},
      balanceActions: [
        {
          id: '1',
          name: this.$t('Manual charge')
        },
        {
          id: '2',
          name: this.$t('Manual deduction')
        },
        {
          id: '3',
          name: this.$t('Bet refund')
        },
        {
          id: '4',
          name: this.$t('Make up the draw')
        },
        {
          id: '5',
          name: this.$t('Others')
        }
      ]
    }
  },
  props: {
    formData: Object,
    editType: String,
    onClose: Function,
    onUpdate: Function,
    visible: Boolean
  },
  watch: {
    formData: {
      handler (nv, ov) {
        this.onFormDataChanged()
      },
      deep: true
    }
  },
  computed: {
    // 嵌入營運商的 state 資料
    ...mapGetters('operator', {
      allOperatorsForSelector: 'getAllForSelector'
    }),
    allOperators () {
      return this.allOperatorsForSelector.filter(op => op.actived)
    },

    // 嵌入 auth 的 state 資料
    ...mapGetters('auth', [
      'userInfo'
    ]),

    operatorEditable () {
      return this.isEditMode && this.isAdministrator
    }
  },
  methods: {
    ...mapActions('operator', { getAllSuperOperators: 'findAll' }),
    visibleChange (boolean) {
      if (!boolean) {
        this.isUpdating = false
        this.superOperatorList = []
        return this.onClose()
      }

      this.formData.isReady = true
      this.superOperatorList = [
        { id: 0, code: this.$t('None'), name: this.$t('None') },
        ...this.allOperators.filter(op => op.role === 'SuperOperator')
      ]

      this.onFormDataChanged()
    },

    updateBalance () {
      this.isUpdating = true
      this.onUpdate()
    },

    onCommentIdChange (idx) {
      const bl = this.formData.balances[idx]
      bl.comment = _.find(this.balanceActions, { id: bl.commentId }).name
      setTimeout(() => {
        this.$nextTick(() => {
          console.log(`bl.commentId === '5'`, bl.commentId === '5')
          this.showComment[bl._id] = bl.commentId === '5'
        })
      }, 200)
    },

    onFormDataChanged () {
      if (!this.formData) {
        return
      }

      if (this.formData && this.formData.isReady && !this.formData.isDirty) {
        this.formData.isDirty = this.formData.isReady && !this.formData.isDirty
      }

      if (this.formData && !this.formData.comment) {
        this.formData.comment = this.balanceActions[0].name
      }
    },

    dividerTitle (item) {
      if (item.provider && item.provider.name) {
        return item.provider.name
      }
      return ''
    }
  },
  async mounted () {

  }
}
</script>
<style lang="sass">
.input-prefix
  line-height: 34px
  position: absolute
  left: 0
  padding-left: 8px
  color: #aaa

.val
  line-height: 32px

</style>
